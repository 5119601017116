<template>
    <div class="d-flex my-2">
        <v-icon
            :color="applicantData.color"
            dark
            class="justify-center text-uppercase mr-2"
            >{{ applicantData.icon }}</v-icon
        >
        <a :href="buildApplyUrl(applicantData.meta.params)">
            <h4 class="font-weight-regular">{{ applicantData.meta.name }}</h4>
        </a>
        <v-icon
            class="ml-2"
            color="secondary"
            dark
            v-if="
                $_.get(applicantData, 'order.order.status', '') ===
                'APPLICANT_COMPLETED'
            "
        >
            mdi-check-circle
        </v-icon>
    </div>
</template>
<script>
import ApplicantDisplay from "@components/uploads/mixins/ApplicantDisplay";
export default {
    name: "application-list-applicant",
    components: {},
    mixins: [ApplicantDisplay],
    props: {
        applicant: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        applicantData() {
            return {
                ...this.applicant,
                ...this.applicantDisplay(this.applicant.type),
            };
        },
    },
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>