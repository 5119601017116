var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex my-2" },
    [
      _c(
        "v-icon",
        {
          staticClass: "justify-center text-uppercase mr-2",
          attrs: { color: _vm.applicantData.color, dark: "" }
        },
        [_vm._v(_vm._s(_vm.applicantData.icon))]
      ),
      _c(
        "a",
        { attrs: { href: _vm.buildApplyUrl(_vm.applicantData.meta.params) } },
        [
          _c("h4", { staticClass: "font-weight-regular" }, [
            _vm._v(_vm._s(_vm.applicantData.meta.name))
          ])
        ]
      ),
      _vm.$_.get(_vm.applicantData, "order.order.status", "") ===
      "APPLICANT_COMPLETED"
        ? _c(
            "v-icon",
            { staticClass: "ml-2", attrs: { color: "secondary", dark: "" } },
            [_vm._v(" mdi-check-circle ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }